import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import originalLogo from "../cctlogo.png";
import { IoMenu } from "react-icons/io5";
import mobileImage from "../cctlogo12.png";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import homeIcon from "../home.png";
import UmrahIcon from "../umrah.png";
import HajjIcon from "../hajj.png";
import teamIcon from "../team.png";
import holidayIcon from "../holiday.png";
import testimonialIcon from "../testimonials.png";
import contactIcon from "../contact us.png";
import CCTlue from "../cct blue (1).png";
import snapchat from "../5296509_snapchat logo_ghost_snap_snapchat_social media_icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FaFacebookSquare } from "react-icons/fa";
const Header = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/Umrah");
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleTestClick = () => {
    navigate("/Testimonials");
  };
  const handleHajjClick = () => {
    navigate("/Hajj");
  };
  const handleHolidayClick = () => {
    navigate("/Holiday");
  };
  const handleContactClick = () => {
    navigate("/ContactUs");
  };
  const handleTeamClick = () => {
    navigate("/Team");
  };

  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  useEffect(() => {
    if (isScrollDisabled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isScrollDisabled]);

  const toggleScroll = () => {
    setIsScrollDisabled(!isScrollDisabled);
  };

  return (
    <Wrapper>
      <div className="screen">
        <div className="topline">
          <div className="IconHolder">
           

            <a href="https://x.com/clearcuttravel">
            <TwitterIcon className="social-icon" />
            </a>

            <a 
            href="">
            
            <FaFacebookSquare className="social-icon" />
            </a>

            <a href="https://www.tiktok.com/@clearcuttravel?_t=8on37v8pbAO&_r=1">
            <FontAwesomeIcon icon={faTiktok} className="social-icon7" />
            </a>

            <a href="https://www.instagram.com/clearcuttravel?igsh=dWJodnJ3ODM4NDBn">
            <InstagramIcon className="social-icon" />
            </a>
            <a href="https://uk.linkedin.com/">
            <LinkedInIcon className="social-icon" />
            </a>
          </div>

          <div className="contactHolder">
            <div className="whatsapp-number">
              <WhatsAppIcon className="social-icon2" />
              <div className="text">0746 671 6956</div>
            </div>

            <div className="whatsapp-number">
              <MailOutlineIcon className="social-icon2" />
              <div className="text">enquiries@clearcut.com</div>
            </div>

            <div className="whatsapp-number">
              <AccessTimeIcon className="social-icon2" />
              <div className="text">Hours: Mon-Sat: 9am - 9pm</div>
            </div>


          </div>
        </div>
        <div className="lower">
          <div className="lower-inner">
            <div className="navigations">
              <div className="navigation-item" onClick={handleButtonClick}>
                Umrah
              </div>

              <div className="navigation-item" onClick={handleHajjClick}>
                Hajj
              </div>

              <div className="navigation-item" onClick={handleHolidayClick}>
                Halal Holidays
              </div>

              <div className="logoHolder">
                <img
                  src={originalLogo}
                  className="logo"
                  onClick={handleHomeClick}
                />
              </div>
              <div className="navigation-item" onClick={handleTeamClick}>
                Team
              </div>
              <div className="navigation-item" onClick={handleTestClick}>
                Testimonials
              </div>
              <div className="navigation-item" onClick={handleContactClick}>
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isScrollDisabled && (
        <div className="mobile-screen">
          <IoMenu className="menuIcon" onClick={toggleScroll} />
        </div>
      )}

      <img src={mobileImage} className="mobile-logo"                   onClick={handleHomeClick}
      />
      {isScrollDisabled && (
        <div className="navigator">
          <CloseRoundedIcon className="cross-icon" onClick={toggleScroll} />

          <div className="navi-inner">
            <div className="navi-logo-holder">
              <div className="logo-inner">
                <img src={CCTlue} className="navi-logo" />
              </div>
            </div>

            <div className="navi-buttons">
              <div className="navi-click" onClick={() => navigate("/")}>
                <img src={homeIcon} className="click-icon" />
                <div className="click-name">Home</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Umrah")}>
                <img src={UmrahIcon} className="click-icon" />
                <div className="click-name">Umrah</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Hajj")}>
                <img src={HajjIcon} className="click-icon" />
                <div className="click-name">Hajj</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Holiday")}>
                <img src={holidayIcon} className="click-icon" />

                <div className="click-name">Holiday</div>
              </div>

              <div
                className="navi-click"
                onClick={() => navigate("/Testimonials")}
              >
                <img src={testimonialIcon} className="click-icon" />
                <div className="click-name">Testimonials</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Team")}>
                <img src={teamIcon} className="click-icon" />{" "}
                <div className="click-name">Team</div>
              </div>

              <div
                className="navi-click"
                onClick={() => navigate("/ContactUs")}
              >
                <img src={contactIcon} className="click-icon" />{" "}
                <div className="click-name">Contact Us</div>
              </div>
            </div>

            <div className="divider" />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 150px;
  width: 100%;
  position: absolute;
  z-index: 10;

  .draw {
    position: absolute;
  }
  .optionpanel {
    position: absolute;
    height: 150px;
    width: 80%;
    bottom: -160px;
    z-index: 10;
    opacity: 0;
    transition: transform 0.3s ease;
    transition: opacity 0.3s ease;
  }
  .mobile-screen {
    display: none;
  }
  .lower {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: none;
    padding-top: 10px;
    position: relative;
  }
  .lower-inner {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .navigations {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .line-below {
    height: 2px;
    width: 100%;
    background-color: white;
    bottom: -10px;
    position: absolute;
    overflow: hidden;
    left: -25%;
    transition: left 0.2s ease-in-out;
    opacity: 0;
  }
  .active-line {
    height: 2px;
    width: 100%;
    background-color: red;
    bottom: -10px;
    position: absolute;
    overflow: hidden;
    transition: left 0.2s ease-in-out;
    opacity: 0;
    left: 0;
    opacity: 1;
  }
  .navigation-item:hover .line-below {
    left: 0;
    opacity: 0.75;
  }

  .navigation-item {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: white;
    position: relative;
    cursor: pointer;
    width: 10%;
    text-align: center;
    text-transform: uppercase;
  }
  .arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 100%);
    height: 100px;
    width: 100px;
    background-color: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    opacity: 0;
    transition: transform 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .navigation-item:hover .arrow {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  .navigation-item:hover .arrow {
    display: block;
  }
  .lower-inner:hover .optionpanel {
    display: block;
    transform: translate(-10%, 0);
    opacity: 1;
  }
  .logoHolder {
    height: 50%;
    width: 10%;
  }
  .logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .logo:hover {
    cursor: pointer;
  }
  .topline {
    height: 45px;
    width: 100%;
    background-color: #24228f;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .IconHolder {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: auto;
    padding-left: 2%;
    padding-right: 1%;
  }
  .contactHolder {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-right: 2%;
  }
  .whatsapp-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-left: 2px;
    margin-right: 2px;
    font-family: "Roboto", sans-serif;
  }
  .text {
    font-size: 14px;
    color: white;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
  }
  .social-icon {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-size: 20px;
    padding: 5px;
    border-radius: 50%;
  }

  .social-icon7 {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-size: 17px;
    padding: 5px;
    border-radius: 50%;
  }
  .social-icon3{
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-size: 20px;
    padding: 5px;
    height: 18px;
    width: 18px;
    margin-top: 5px;
  }
  .social-icon2 {
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    font-size: 20px;
  }
  .mobile-logo {
    display: none;
  }
  @media (max-width: 768px) {
    position: relative;
    height: 60px;
    background-color: #1328bc;
    margin-bottom: 0px;
    margin-top: 0px;

    .screen {
      display: none;
    }
    .mobile-screen {
      display: flex;
      align-content: center;
      align-items: center;
    }
    .menuIcon {
      color: white;
      font-size: 50px;
      margin-top: 4px;
    }
    .mobile-logo {
      display: grid;
      position: absolute;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .drawer {
      background-color: black;
    }
    .navigator {
      position: absolute;
      background-color: white;
      height: 100vh;
      width: 100vw;
      background-color: #f2f2f2;
    }
    .cross-icon {
      position: absolute;
      right: 20px;
      top: 27px;
      font-size: 30px;
      color: #1328bc;
      z-index: 15;
    }
    .navi-inner {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .navi-logo-holder {
      height: 80px;
      width: 100%;
      margin-top: 2%;
      position: relative;
      display: flex;
      justify-content: start;
      align-content: center;
      flex-direction: row;
    }
    .logo-inner {
      height: 100%;
      width: 100%;
      margin: 2%;
      display: flex;
      justify-content: start;
      position: relative;
    }
    .navi-logo {
      height: 55px;
      width: auto;
      margin-left: 7px;
    }

    .navi-buttons {
      height: auto;
      width: 100%;
      margin-top: 5%;
      position: relative;
    }
    .navi-click {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 2%;
      width: 45%;
      margin: 2%;
    }
    .click-icon {
      /* font-size: 35px; */
      height: 25px;
      width: 25px;
    }
    .click-name {
      font-size: 19px;
      width: 75%;
      font-weight: 500;
    }
    .divider {
      margin-top: 10%;
      height: 2px;
      width: 100%;
      background-color: #1328bc;
      opacity: 0.1;
    }
    .socials-navi {
      height: auto;
      margin-top: 5%;
      padding-left: 5%;
    }
    .navi-icon {
      font-size: 45px;
    }
    .navi-textholder {
      display: flex;
      align-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .navi-icon-holder {
      margin-top: 5%;
    }

    .icon2 {
      font-size: 25px;
      color: #1328bc;
    }
    .navi-text {
      font-size: 17px;
      font-weight: 500;
      margin-left: 5px;
    }
  }
`;
export default Header;
