import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./pages/Hero";
import Umrah from "./pages/Umrah";
import Hajj from "./pages/Hajj";
import HalalHoliday from "./pages/HalalHoliday";
import Testimonials from "./pages/Testimonials";
import ContactUs from "./pages/ContactUs";
import Team from "./pages/Team";

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Hero />} />
      <Route path="/Umrah" element={<Umrah />} />
      <Route path="/Hajj" element={<Hajj />} />
      <Route path="/Holiday" element={<HalalHoliday />} />
      <Route path="/Testimonials" element={<Testimonials />} />
      <Route path="/ContactUs" element={<ContactUs />} />
      <Route path="/Team" element={<Team />} />
      
    </Routes>
  </Router>
  );
}

export default App;
