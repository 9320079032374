import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Image2 from "../teamwork-3213924_1280 (1).jpg";
import EmployeeCard from "../components/EmployeeCard";
import React, { useEffect } from "react";

function Team() {
  const handleScroll = () => {
    window.scrollBy({
      top: 450,
      left: 0,
      behavior: "smooth",
    });
  };

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  

  const teamMember = [
    {
      id: 1,
      name: "John Doe",
      designation: "Cheif Executive Officer",
      image:
        "https://as1.ftcdn.net/v2/jpg/02/43/12/34/1000_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
      background:
        "https://cdn.prod.website-files.com/6009ec8cda7f305645c9d91b/6010813f5ce191dc4701ea9b_6002086f72b72724ad01dec6_flexbox-and-css-grid-p-2000.jpeg",
      task: "I help with Accounts",
    },
    {
      id: 2,
      name: "Jane Smith",
      designation: "Cheif Accounts Officer",
      image:
        "https://as1.ftcdn.net/v2/jpg/03/02/88/46/1000_F_302884605_actpipOdPOQHDTnFtp4zg4RtlWzhOASp.jpg",
      background:
        "https://media.istockphoto.com/id/488759322/vector/abstract-blue-mosaic-background-with-white-copyspace-area.jpg?s=2048x2048&w=is&k=20&c=BdIUc19fWyuaHyo1hCqSlSKxZp7dbZvvcqV3yUTRfTo=",
      task: "I help with Accounts",
    },
    {
      id: 3,
      name: "John Doe",
      designation: "Hafiz",
      image:
        "https://as1.ftcdn.net/v2/jpg/02/99/04/20/1000_F_299042079_vGBD7wIlSeNl7vOevWHiL93G4koMM967.jpg",
      background:
        "https://media.istockphoto.com/id/871234626/vector/digital-marketing-seamless-pattern-with-thin-line-icons-searching-idea-development.jpg?s=2048x2048&w=is&k=20&c=jfuNsNM8fUyLBIuG2AvWBRsiDdDSGdbiq4szpdQtyQA=",
      task: "I help with Accounts",
    },
    {
      id: 4,
      name: "Jane Smith",
      designation: "Financial Advisor",
      image:
        "https://as2.ftcdn.net/v2/jpg/03/13/37/31/1000_F_313373132_b9Az7XaGLRvSLHXlINXBIGPMIOLok8ZB.jpg",
      background:
        "https://media.istockphoto.com/id/1482758944/vector/abstract-pink-halftone-background-with-dotted-trendy-design.jpg?s=2048x2048&w=is&k=20&c=4qgUe8ByukIGY6GoNK1u6urt9kducy7tGuhrdxeQ8KA=",
      task: "I help with Accounts",
    },
    {
      id: 5,
      name: "John Doe",
      designation: "Hafiz",
      image:
        "https://as1.ftcdn.net/v2/jpg/02/99/04/20/1000_F_299042079_vGBD7wIlSeNl7vOevWHiL93G4koMM967.jpg",
      background:
        "https://media.istockphoto.com/id/871234626/vector/digital-marketing-seamless-pattern-with-thin-line-icons-searching-idea-development.jpg?s=2048x2048&w=is&k=20&c=jfuNsNM8fUyLBIuG2AvWBRsiDdDSGdbiq4szpdQtyQA=",
      task: "I help with Accounts",
    },
    {
      id: 6,
      name: "Jane Smith",
      designation: "Financial Advisor",
      image:
        "https://as2.ftcdn.net/v2/jpg/03/13/37/31/1000_F_313373132_b9Az7XaGLRvSLHXlINXBIGPMIOLok8ZB.jpg",
      background:
        "https://media.istockphoto.com/id/1482758944/vector/abstract-pink-halftone-background-with-dotted-trendy-design.jpg?s=2048x2048&w=is&k=20&c=4qgUe8ByukIGY6GoNK1u6urt9kducy7tGuhrdxeQ8KA=",
      task: "I help with Accounts",
    },
  ];

  return (
    <Wrapper>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">Coming Soon</div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <img className="bg-image" src={Image2} />
          <div className="blur-bg" />
        </div>
      </div>

      <div className="spacer" />

      {/* <div className="maintext">People who make it work !</div> */}
      <div className="team-holder">
        <div className="team-inner">
          {/* {teamMember.map((teamMember) => (
            <EmployeeCard
              key={teamMember.id}
              task={teamMember.task}
              background={teamMember.background}
              name={teamMember.name}
              designation={teamMember.designation}
              image={teamMember.image}
            />
          ))} */}
        </div>
      </div>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .maintext {
    margin-top: 1%;
    margin-bottom: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-transform: uppercase;
  }

  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }
  .spacer {
    margin-top: 50px;
  }
  .team-inner {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  .team-holder {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blur-bg {
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 35px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 2%;
    color: #333333;
  }
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    font-family: "Montserrat", sans-serif;
  }
  .upper-divs {
    height: 70vh;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }
  .holders {
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    position: relative;
    background-color: #011526;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
      background-color: #1328bc;
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default Team;
